import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { searchTypesenseDocuments } from 'utils/typeSense';

import { StyledErrorMessage, StyledSearchForm, StyledSearchInput, StyledSearchIcon} from '../../styled';

import { closeMenu } from 'state/actions/drawer';
import { generateSearchResults } from 'state/actions/searchResults';
import createProperties from 'utils/functions/createProperties';

const SearchForm = ({ generateSearchResults, menuData, errorMessage, language, setDropdown }) => {
  const [isInputFieldEmpty, setErrorMessages] = useState(false);
  const [inputs, setInputs] = useState({});
  const { urls } = createProperties(language);

  const useSignUpForm = (callback) => {
    const handleSubmit = (event) => {
      if (event) event.preventDefault();
      searchTypesenseDocuments(inputs, setErrorMessages, generateSearchResults, urls)
    }

    const handleInputChange = (event) => {
      if (event) event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    return { handleSubmit, handleInputChange, inputs };
  }

  const handleCloseMenu = () => {
    if (inputs.searchbox != undefined && inputs.searchbox != "") {
      setDropdown(false) 
    }
  }

  const { handleInputChange, handleSubmit } = useSignUpForm();
  
  const searchPlaceholderText = menuData.search_box_placeholder ? menuData.search_box_placeholder : "";

  const searchErrorMessage = (
    <StyledErrorMessage>
      <i className='warning-icon' />
      <span className="search-error-msg"> {errorMessage} </span> 
    </StyledErrorMessage>
  )

  return (
    <StyledSearchForm onSubmit={handleSubmit} autocomplete="off">

      { isInputFieldEmpty === true ? searchErrorMessage : null }
      
      <StyledSearchInput 
        id="searchbox" 
        name="searchbox"
        aria-label={searchPlaceholderText}
        placeholder={searchPlaceholderText}
        onChange={handleInputChange} 
        value={inputs.searchbox}
        autoComplete="off"
        setErrorBorderColor={isInputFieldEmpty}
      />  
      <StyledSearchIcon 
        type="image" 
        name="submit" 
        src={menuData.search_icon.url} 
        onClick={() => handleCloseMenu()}
      />

    </StyledSearchForm>
  )
}

const mapDispatchToProps = dispatch => ({
  closeMenu: isMenuClosed => dispatch(closeMenu(isMenuClosed)),
  generateSearchResults: results => dispatch(generateSearchResults(results))
})

export default connect(null, mapDispatchToProps)(SearchForm)

SearchForm.propTypes = {
  errorMessage: PropTypes.string,
  generateSearchResults: PropTypes.func,
  language: PropTypes.string,
  menuData: PropTypes.object,
  setDropdown: PropTypes.func
}
